$baseFont:'Montserrat', sans-serif;
$baseColor:#1C1C2D;
$secondaryColor:#AD3E7E;
$blueColor: #2160DB;
$white:#fff;
$blackColor:#000;
$boderColor:#A73F83;
$dimbg:#323245;
$hoverColor: #E3305A;
$buttonDisabled:#496989;
$noFeedColor:#00FFBA;
$gradientColor: linear-gradient(90deg, rgba(176,60,124,1) 0%, rgba(137,70,150,1) 33%, rgba(103,79,173,1) 73%, rgba(77,76,181,1) 100%);;
$gradientReverse: linear-gradient(268deg, #B63B78 0%, #5155BC 100%);
