@import "../../assets/theme/clr_var.scss";

.buttonStyle.btn {
  cursor: pointer;
  padding: 0;
  background-image: $gradientColor;
  border-radius: 25px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  border: 0;
  font-family: $baseFont;
  z-index: 1;
  transition: all ease 0.5s;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    min-width: 125px;
    height: 42px;
    font-size: 14px;
  }
  &:focus {
    box-shadow: unset;
    outline: unset;
  }
  img {
    display: none;
  }
  &:after {
    position: absolute;
    content: "";
    background-image: linear-gradient(
      90deg,
      rgba(77, 76, 181, 1) 0%,
      rgba(137, 70, 150, 1) 73%,
      rgba(176, 60, 124, 1) 100%
    );
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 25px;
    top: 0;
    opacity: 0;
    left: 100%;
    transition: all ease 0.5s;
  }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
      left: 0;
      transition: all ease 0.5s;
    }
  }
}
.logout_btn {
  img {
    margin-bottom: 0 !important;
  }
}
.logout_btn {
    img {
        margin-bottom: 0 !important;
    }
}
