@import "../../assets/theme/clr_var.scss";

.farmcard.card{
  background: $gradientReverse;
  padding: 15px 25px;
  border-radius: 25px;
  border: 0; 
  border: none;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
  @media (max-width: 767px) {
    padding: 15px 18px;
  }
  @media (max-width: 767.98px) {
    p {
      margin-bottom: 0;
    }
  }
  h3{
    font-size: 22px; font-weight: 700; margin-bottom:0;
    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }
  .cions{
    margin-right: 20px;
    @media (max-width: 1440px) {
      margin-right: 15px;
    }
    .uppr{
      position: relative;
      z-index: 1;
    }
    .dwn {
        margin-left: -10px;
    }
    img{
        width: 39px;
        @media (max-width:991px){
          width: 35px;
        }
    }
  }
  .coin_name{
    span{
      font-size: 11px; color: $white; position: relative; top: -6px;
    }
  }
  .direction{
    margin-top: 15px;
    display: flex; align-items: center; justify-content: space-between;
    .linkBtn{
      background-color: $white; background-image: none;
      color: #181928;
      display: flex; text-decoration: none;
      align-items: center; height: 42px;
      flex-direction: row-reverse; padding: 0 25px;
      &::after{
        display: none;
      }
      @media (max-width: 1440px) { 
        padding: 0 20px; height: 40px;
      }
      img{
        display: inline-block; width: 14px; margin-right: 10px; 
      }
    }
  }
}
@media (max-width: 767.98px) {
  .cardStyle.graph_container.card {
    margin-top: 15px;
  }
}
