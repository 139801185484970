$baseFont:'Rubik', sans-serif;
$secondaryFont:'Fredoka One', cursive;
$baseColor:#111326;
$secondaryColor:#E3D32D;
$whiteColor:#fff;
$blackColor:#000;
$blueLign:#1C3D5A;
$swapStepbg:#496989;
$buttonDisabled:#496989;
$noFeedColor:#00FFBA;
