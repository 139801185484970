@import "../../assets/theme/clr_var.scss";

.coinItem_style {
  transition: all 0.25s;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $secondaryColor;
  }
  &.active {
    cursor: not-allowed;
    background-color: $dimbg;
    opacity: 0.7;
  }
  img {
    width: 38px;
    height: 38px;
    margin-right: 20px;
  }
  .titleStyle {
    font-family: $baseFont;
    font-size: 18px;
    font-weight: 400;
    color: $white;
    margin: 0;
    padding: 0;
  }
}
