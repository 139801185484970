@import "../../assets/theme/clr_var.scss";

.headingStyle {
  display: flex;
  background-color: $dimbg;
  padding-left: 140px;
  @media (max-width: 1199px) {
    padding-left: 44px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  h6 {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    padding: 9px;
    min-width: 195px;
    &.earnTxt {
      margin-left: -22px;
    }
  }
}

.planet_strip {
  .stakedValue {
    display: flex;
    padding: 15px 50px 13px;
    padding-left: 50px;
    align-items: center;
    @media (max-width: 1199px) {
      padding-left: 18px;
      padding-right: 15px;
    }
    @media (max-width: 767px) {
      padding-left: 15px;
      display: block;
    }
    .comnDiv {
      margin-right: 85px;
      @media (max-width: 1199px) {
        margin-right: 40px;
      }
      @media (max-width: 767px) {
        margin-right: 0px;
      }
      h6 {
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
        margin: 5px 0;
        white-space: nowrap;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .boldTxt {
        margin-left: 17px;
        h6 {
          font-weight: 600;
        }
      }
      .stkd_title {
        @media (max-width: 767px) {
          width: 115px;
        }
      }
    }
    .vwdoc_btn {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1199px) {
        width: auto;
        justify-content: end;
        flex: 1;
      }
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        flex: 1 1;
        flex-direction: column;
      }
    }
    .linksTxt {
      a {
        display: block;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: $white;
        margin: 5px 0;
      }
    }
    .unlockWallet_Btn {
      margin-left: auto;
      height: 37px;
      font-size: 11px;
      font-weight: 700;
    }
  }
}
.glaxy_bar.btn {
  .coin_detail {
    .QrIcon {
      width: 20px;
      height: 20px;
    }
    .prcentx {
      @media (max-width: 767px) {
        position: static;
        width: 28px !important;
        height: 28px !important;
        font-size: 9px !important;
        line-height: 30px !important;
        margin-right: 10px !important;
      }
    }
  }
  .cions {
    position: relative;
    .title_nm {
      font-size: 12px;
      position: absolute;
      display: none;
      @media (max-width: 767px) {
        display: block;
        right: 2px;
      }
    }
  }
}
