@import "../../assets/theme/clr_var.scss";

.connect_wallet.supply_mode {
  .modal-dialog {
    display: flex;
    flex: 1;
    font-family: $baseFont;
    height: auto;

    .modal-body {
      display: flex;
      padding: 20px ​15px 5px;
      flex-direction: column;
    }
    .modal-content {
      background: #1c1c2d;
      color: $white;
      border: 1px solid $boderColor;
      border-radius: 19px;
      .modal-header {
        padding: 30px 45px;
        border: 1px solid #a73f83;
        @media (max-width: 767px) {
          padding: 20px;
        }
        .modal-title {
          font-size: 20px;
          font-weight: 400;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        .btn-close {
          background: url(../../assets/images/icon_close_modal.png) center
            no-repeat;
          outline: none;
          background-size: 13px;
          background-color: #ad3e7e;
          opacity: 1;
          border-radius: 50px;
        }
      }
      .baseToken_style {
        padding-top: 20px;
        @media (max-width: 767px) {
          padding-top: 0;
        }
        ul {
          padding: 0 25px;
          @media (max-width: 767px) {
            padding: 0;
            margin-bottom: 0;
          }
          li {
            display: block;
            margin-bottom: 10px;
            margin-right: 0;
            button.btn {
              padding: 15px 20px;
              border-radius: 30px;
              width: 100%;
              text-decoration: none;
              font-size: 14px;
              font-weight: 600;
              color: #ffffff;
              font-family: $baseFont;
              display: block;
              text-align: left;
              height: auto;
              background: #323245;
              position: relative;
              border: 0;
              @media (max-width: 767px) {
                padding: 12px 20px;
                font-size: 13px;
              }
              &:focus {
                box-shadow: none;
              }
              img {
                width: 38px;
                position: absolute;
                right: 15px;
                top: 6px;
                height: 38px;
                background: #fff;
                border-radius: 50%;
                padding: 1px;
                @media (max-width: 767px) {
                  width: 34px;
                  right: 6px;
                  top: 4px;
                  height: 34px;
                }
              }
            }
            .pool_list {
              ul {
                padding: 0;
                li {
                  display: flex;
                  font-size: 15px;
                  font-weight: 600;
                  align-items: center;
                  justify-content: space-between;
                  p {
                    margin: 0;
                  }
                }
              }
            }
            .estimate {
              font-size: 13px;
            }
            &.d_flx {
              display: flex;
              align-items: center;
              justify-content: start;
              h2 {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 0;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
