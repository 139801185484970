@import '../../assets/theme/clr_var.scss';


.header_style{
    display: flex;
    height: 94px;
    background: $baseColor;
    align-items: center;
    padding-left: 27px;
    padding-right: 27px;
    justify-content: space-between;
    z-index: 3;
    position: sticky; 
    top:0;
    transition: all .25s;
    @media (max-width:767px){
        padding-left: 15px;
        padding-right: 15px; height: 72px;
    } 
    &.isFixed{
        top:-100px;
    }

    .header_left_style{
        display: flex;
        align-items: center;
        .for_desktop, .for_mobile{
            position: relative;
            top: -3px; 
            img{ 
                width: 35px; cursor: pointer;
            }
        }
        .header_logo{
            width: 225px;
            height: 35px;
            background: url("../../assets/images/Niob-Header-Logo.svg") no-repeat;
            display: flex;
            font-family: $baseFont;
            margin-left: 14px;
            display: inline-block;
            transition: all .25s;
            @media(max-width:991px){
                background: url("../../assets/images/token_icons/NIOB.svg") no-repeat;
                width: 38px;
                height: 37px;
                background-size: 37px;                
            }
        }
        .for_mobile{
            display: none;
        }
        @media(max-width:960px){
            .for_desktop{
                display: none;
            }
            .for_mobile{
                display: block; margin:9px 0 0 ;
            }
        }
       
    }
    .header_right_style{
        text-align: right;

        a.connect__Link{
            min-width: 158px;
            text-align: center;
            transition: all .25s;
            @media(max-width:960px){
                
            }
            
        }
    }
    
}