@import "../../assets/theme/clr_var.scss";

.container_wrap.trade {
  padding: 100px 0;
  background-image: url("../../assets/images/swap-bg.jpg");
  @media (max-width: 767px) {
    padding: 50px 0;
  }
  .comnBlk {
    padding: 40px 10px 30px;
    padding-top: 0;
    max-width: 600px;
    margin: 0 auto;
    &.lang-blk {
      padding: 0px 0px 0;
      border-radius: 19px;
      overflow: hidden;
      margin-bottom: 20px;
      .tab-content {
        margin-top: 0;
      }
    }
    @media (max-width: 767px) {
      padding: 0px 5px 20px;
    }
  }
  .swap_title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .tab-content {
    margin-top: 35px;
  }
  .swapScreen {
    .container {
      position: relative;
      @media (max-width: 767px) {
        padding: 0;
      }
      .settingsTop {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        list-style: none;
        right: 25px;
        li {
          margin-right: 15px;
          // color: $greenColor;
        }
      }
    }

    .nav-tabs.nav {
      max-width: max-content;
      padding: 0;
      background: transparent;
      border-radius: 0;
      border: none !important;
      justify-content: center;
      margin: 0 auto;
      &.lang-tab {
        padding: 0;
        margin-bottom: 1px !important;
        .nav-link {
          padding: 12px 71px;
          @media (max-width: 1600px) {
            padding: 12px 47px;
          }
          @media (max-width: 1285px) {
            padding: 12px 39px;
          }
          &.active {
            background: #323245;
            border-radius: 0;
            color: #fff !important;
          }
        }
      }

      .nav-link {
        padding: 12px 54px;
        border: none !important;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        &:hover,
        &:active,
        &:focus {
          color: $white !important;
        }
        &.active {
          background: transparent $gradientColor padding-box;
          border-radius: 0 0 17px 17px;
          color: $white !important;
        }
        @media (max-width: 767px) {
          padding: 9px 28px;
          font-size: 14px;
        }
      }
    }
    .lang-content {
      padding: 15px 30px;
      font-size: 12px;
      line-height: 17px;
      padding-bottom: 30px;
      border-radius: 0 0 19px 19px;
      background: #aa3e80;
      @media (max-width: 1199px) {
        padding: 15px 10px;
      }
      h2 {
        font-size: 25px;
        line-height: 46px;
        @media (max-width: 1400px) {
          font-size: 18px;
        }
      }
    }
    .convert_plus {
      border-radius: 100px;
      margin: 15px auto;
      display: flex;
      justify-content: center;
      img {
        width: 40px;
      }
    }

    .linkDirection {
      color: #bdbdbd;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
      display: inherit;
      text-decoration: none;
      cursor: pointer;
      svg {
        margin-left: 18px;
        //   @include transition(all ease 0.5s);
      }
      &:hover {
        //   @include transition(all ease 0.5s);
        //   color: $white;
        svg {
          margin-left: 20px;
        }
      }
    }
    .tipDirection {
      background: #332247 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
    }
    .swapBtn_col {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      .swapBtn {
        width: 206px;
        font-size: 16px;
        font-weight: 700;
        &:hover {
          color: $white;
        }
        &.dismissBtn {
          background-color: $dimbg;
          background-image: none;
        }
      }
    }
    .priceSec_col {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      padding: 0 26px;
      @media (max-width: 767px) {
        padding: 0;
      }
      h5 {
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin: 5px 0;
      }
    }
    .settingSec {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      img {
        cursor: pointer;
      }
      .timerImg {
        width: 26px;
        height: 26px;
        margin-right: 15px;
      }
      h4 {
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 16px;
          margin-bottom: 0px;
        }
      }
    }

    .firstPro_Note {
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 15px;
      background-color: $dimbg;
      border: 1px solid $boderColor;
      p {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
    .card_style_bottom,
    .lp-class {
      margin: 20px 0 0;
      ul {
        padding: 15px 20px;
        margin-bottom: 0;
        li {
          font-size: 13px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
        }
      }
    }
    .lp-class {
      border: 1px solid #a73f83;
      border-radius: 20px;
      padding: 25px 15px 10px;
      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .LptokensList {
        padding: 0;
        li {
          span {
            img {
              width: 30px;
            }
          }
        }
      }
    }
    .selectCurrency_modal {
      margin: 20px 15px 0;
      padding: 20px;
      .lessMargin_bottom {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;
      }
      h2 {
        font-size: 20px;
        margin-bottom: 0;
      }
      .confirm_list {
        padding: 0;
        li {
          display: flex;
          font-size: 13px;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
          p {
            margin-bottom: 0;
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .liquidtySec {
    max-width: 385px;
    margin: auto;
    .selectCoin_style.col {
      label {
        font-size: 13px;
        font-weight: 700;
      }
      .selectCoin_right_style {
        .coin_Img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
    .poolSec {
      margin-top: 10px;
      h6 {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .poolDiv {
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        border: 1px solid $boderColor;
        padding: 25px 15px;
        margin-bottom: 15px;
        span {
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          small {
            font-size: 10px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .add_lq_box {
    .settingSec {
      align-items: center;
      .in_title {
        p {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    .add_liq {
      margin: 25px 0 40px;
      .linkBtn {
        padding: 0 35px;
        @media (max-width: 767px) {
          padding: 0 20px;
          font-size: 13px;
          line-height: 40px;
        }
        img {
          display: inline-block;
          width: 14px;
          margin-left: 5px;
          margin-top: -2px;
        }
      }
    }
    .tokeninfo {
      font-size: 13px;
      opacity: 0.9;
      font-weight: 400;
      letter-spacing: 0.5px;
      a {
        color: $secondaryColor;
        text-decoration: underline;
        font-weight: 600;
      }
    }
    .liquidity_list {
      margin: 30px 0;
      padding: 20px;
      border-radius: 12px;
      background: $dimbg;
      h3 {
        font-size: 18px;
        opacity: 0.5;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
      }
    }
    // your liquidity accordian
    .yourLiq_accordian {
      margin-bottom: 10px;
      .accordion-item {
        background-color: $dimbg;
        border-radius: 16px;
        border: 2px solid #a73f83;
        color: $white;
        .accordion-header {
          .accordion-button {
            color: $white;
            background-color: $dimbg;
            border: 0;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 600;
            &:hover,
            &:active,
            &:focus {
              box-shadow: unset;
            }
            &::after {
              margin-left: 20px;
              background-image: url(../../assets/images/down-arrow.png);
              background-size: 15px;
              background-position: center;
            }
          }
          .accordion-body {
            @media (max-width: 767px) {
              padding: 12px;
            }
          }
        }
        .amountDiv {
          display: flex;
          justify-content: space-between;
          ul {
            padding: 0;
            li {
              font-size: 13px;
              font-weight: 700;
              margin-bottom: 10px;
            }
          }
        }
      }

      .remove_liq {
        .remove_liq_btn {
          padding: 10px 20px;
        }
      }
    }
  }
}

.LptokensList {
  padding: 0;
  li {
    margin-bottom: 5px;
  }
}

.btnSwap {
  img {
    width: 35px;
    height: 35px;
  }
}

.importpoolbox {
  background: transparent;
  border-color: $secondaryColor;
  // padding: 10px;
  height: 50px;
  color: $white;
  border-radius: 27px;
  margin: 20px 0;
  img {
    margin-right: 10px;
    width: 17px;
  }
}
