.ct-toast-success {
    h4.ct-heading{
        color: #000 !important;
    }
  }

.ct-toast-error {
    h4.ct-heading{
        color: #000 !important;
    }
  }

.ct-toast-info {
    h4.ct-heading{
        color: #000 !important;
    }
  }