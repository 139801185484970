@import '../../assets/theme/clr_var.scss';


$sidebar-bg-color: $baseColor !default;
$submenu-bg-color: $dimbg !default;
$sidebar-width: 236px !default;
$icon-bg-color: transparent !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';


.sidebar_style{ 
    position: fixed;
    left:0px;
    top:0px; 
    z-index: 2 !important;   
    padding-top: 94px;
    transition: all .25s;
    background-color: #000;
    &.isFixed{
        padding-top: 0px;
    }
    @media(max-width:960px){
        left:-100%;
        &.small_nav{
            left:0px;
        }
    }
    @media (max-width:767px){
        padding-top: 72px;
    }
   
}
.pro-sidebar .pro-menu a, .pro-sidebar  .pro-item-content{
    font-size: 16px;
    font-family: $baseFont;        
    font-weight: 700; color: $white;
}
.react-slidedown li.pro-menu-item .pro-item-content a{
    font-weight: 500;
}
li.pro-menu-item{
    width: 236px;
    position: relative;    

    &.active{
        &:before{
            position: absolute;
            content:'';
            left:0px;
            height: 100%;
            width:5px;
            top:0px;
            background: $hoverColor;
        }
    }
    .pro-menu a, .pro-item-content{
        font-size: 16px;
        font-family: $baseFont;        
        font-weight: 700;
    }
    .home_nav_icon{
        background: url(../../assets/images/Homebase-Icon.svg);
    }
    .trade_nav{
        background: url(../../assets/images/Trade-Icon.svg);
    }
    .farm_nav_icon{
        background: url(../../assets/images/farm-plantes-icon.svg);
    }
    .pools_nav_icon{
        background: url(../../assets/images/Pools-Icon.svg);
    }
    .lottery_nav_icon{
        background: url(../../assets/images/Lottery-Icon.svg);
    }
    .referrals_nav_icon{
        background: url(../../assets/images/Referrals-Icon.svg);
    }
    .audits_nav_icon{
        background: url(../../assets/images/audits-icon.svg);
    }
    .features_nav_icon{
        background: url(../../assets/images/features-icon.svg);
    }
    .listings_nav_icon{
        background: url(../../assets/images/listings-icon.svg);
    }
    .analytics_nav_icon{
        background: url(../../assets/images/analytics-icon.svg);
    }
    .more_nav_icon{
        background: url(../../assets/images/more-icon.svg);
    }
    .pred_nav_icon{
        background: url(../../assets/images/PRDT-Icon.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    i{
        width: 20px;
        height: 20px;
        background-repeat: no-repeat !important;
        background-position: center !important;
    }
}
.pro-sub-menu ul li .pro-inner-item::before{display: none !important;}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow{
    border-color: $blueColor;
    padding:3.5px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #382a49 !important; overflow: hidden;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    animation: unset; -webkit-transition: all ease 0.5s;
    transform: rotate(360deg); transition: all ease 0.5s;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item .pro-icon-wrapper .pro-icon {
    -webkit-transition: all ease 0.5s;  transition: all ease 0.5s;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 5px 35px 5px 20px;
}
.sidebar_footer{
    border-top: 2px solid #22A7F0 !important;
    flex: 0 0 auto;
    padding: 60px 0;
    display: block;
}
.token-language{
    display: flex;
    justify-content: space-between;
    padding-left: 25px !important;
    padding-right: 25px !important;
    box-sizing: border-box;
    align-items: center;  
    margin-bottom: 17px !important;  
    .token_list{
       
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            color:#fff; font-weight: 500; text-decoration: none;
            font-family: $baseFont;

            img{
                margin-right: 10px;
            }
    
        }
    }
    .lang_list{
        display: flex;
        font-family: $baseFont;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        color:$white;
        img{
            margin-right: 10px; width: 22px;
        }
    }
}

ul.social_links{display: flex; justify-content: space-between;  padding-left: 25px !important;  padding-right: 25px !important; box-sizing: border-box;}
ul.social_links li{ position: relative; transition: all ease 0.5s;}  
ul.social_links li:hover img{width: 30px; transform: rotate(360deg); transition: all ease 0.5s;}  
ul.social_links li img{width: 30px; transition: all ease 0.5s;}  


.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
    
    overflow: unset !important;
}
.pro-sidebar .pro-menu{ 
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    height: 100%;
}

.pro-sidebar.small_nav{
    width: 73px;
    min-width: 73px;
    .token-language, .social_links {
       opacity: 0;
       visibility: hidden;
       @media (max-width: 960px) {
           opacity: 1;
           visibility: visible;
           
       }
    }
    a{
        opacity: 0;
    }
    .pro-item-content{
        opacity: 0;
    }

    @media(max-width:960px){
        width: 236px;
        min-width: 236px;

        a, .pro-item-content{
            opacity:1;
        }
    }
}
